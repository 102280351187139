<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">财务模块</el-breadcrumb-item>
        <el-breadcrumb-item>订单记录</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-tabs type="border-card" v-model="tabName" @tab-change="tabChange">
      <el-tab-pane label="销售订单" name="sellinfo" class="horizbox">
        <el-form label-width="90px" :model="orderParams" ref="orderParams" class="search-form">
          <el-row>
            <el-form-item label="关键词" prop="keyword" label-width="100px">
              <el-col>
                <el-input v-model="orderParams.keyword" @keyup.enter="fetchs" prefix-icon="Search"
                  style="width:150px"></el-input>
              </el-col>
            </el-form-item>

            <el-form-item label="公司" label-width="80px">
              <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable
                @change="departmentId = ''; companyChange();" check-strictly :render-after-expand="false"
                style="min-width: 220px;" />
            </el-form-item>

            <el-form-item label="部门" prop="departmentId" label-width="60px">
              <el-tree-select ref="departments" v-model="departmentId" :data="departments" clearable check-strictly
                @change="fetchs()" :render-after-expand="false" style="min-width:120px" />
            </el-form-item>

            <el-form-item label="渠道平台" prop="platform" label-width="90px">
              <el-col style="width: 150px">
                <!-- <el-select v-model="platform" placeholder="渠道平台" clearable @change="fetchs()" style="max-width: 200px">
                  <el-option v-for="dd in dict.platform" :key="dd.value" :label="dd.label" :value="dd.value">
                  </el-option>
                </el-select> -->
                
                <el-input v-model="platform" @keyup.enter="fetchs" style="width:150px"></el-input>
              </el-col>
            </el-form-item>

            <el-form-item label="订单时间范围" label-width="120px">
              <el-col>
                <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
                  unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs" style="max-width:200px;">
                </el-date-picker>
              </el-col>
            </el-form-item>

            <el-form-item label="是否发货" label-width="100px">
              <el-col>
                <el-select v-model="delivery" placeholder="" clearable @change="fetchs()" style="min-width: 90px">
                  <el-option key="-1" label="未发货" value="-1"></el-option>
                  <el-option key="1" label="已发货" value="1"></el-option>
                </el-select>
              </el-col>
            </el-form-item>

            <el-form-item label="回款金额" label-width="100px">
              <el-col>
                <el-select v-model="paybackPrice" placeholder="" clearable @change="fetchs()" style="min-width: 150px">
                  <el-option key="1" label="有回款金额" value="1"></el-option>
                  <el-option key="0" label="回款金额为0" value="0"></el-option>
                  <el-option key="-1" label="无回款金额" value="-1"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="成本价" label-width="80px">
              <el-col>
                <el-select v-model="costPrice" placeholder="" clearable @change="fetchs()" style="min-width: 150px">
                  <el-option key="1" label="有成本价" value="1"></el-option>
                  <el-option key="0" label="成本价为0" value="0"></el-option>
                  <el-option key="-1" label="无成本价" value="-1"></el-option>
                </el-select>
              </el-col>
            </el-form-item>

            <el-form-item label="" label-width="30px">
              <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
            </el-form-item>
          </el-row>
        </el-form>

        <div class="toolbar" style="margin-top: 0;">
          <el-button plain type="info" icon="download" @click="fetchs('Export')"
            style="float:right;margin:0 3px;">导出明细</el-button>
          <!-- <el-button plain type="primary" icon="upload" style="float:right; margin:0 3px;"
            @click="openUploadDialog()">导入销售单</el-button> -->

          <el-dropdown style="float:right; margin:0 3px;">
            <el-button type="primary">
              导入销售单
              <el-icon>
                <ArrowDown />
              </el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <!-- <el-dropdown-item @click="openUploadDialog">上传销售单表格</el-dropdown-item> -->
                <el-dropdown-item @click="openUploadFeeDialog">上传现有订单扣点</el-dropdown-item>
                <el-dropdown-item @click="syncPreOrders">同步已发货订单</el-dropdown-item>
                <!-- <el-dropdown-item @click="syncDelivery">匹配订单利润</el-dropdown-item> -->
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div style="clear:both;"></div>
        </div>
        <div style="clear:both;"></div>

        <static-table class="table" :pager="orderPager" :columns="orderParams.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">
          <template v-slot:paymentStatus="scope">
            <span style="color:gray;" v-if="scope.row.paymentStatus == 0"></span>
            <span style="color:blue;" v-if="scope.row.paymentStatus == 1">已支付</span>
            <span style="color:red;" v-if="scope.row.paymentStatus == -1">已关闭</span>
          </template>
          <template v-slot:paybackStatus="scope">
            <span style="color:gray;" v-if="scope.row.paybackStatus == 0">无需回款</span>
            <span style="color:blue;" v-if="scope.row.paybackStatus == 1">尚未回款</span>
            <span style="color:green;" v-if="scope.row.paybackStatus == 2">部分回款</span>
            <span style="color: rebeccapurple;" v-if="scope.row.paybackStatus == 200">全部回款</span>
          </template>
          <template v-slot:returnMark="scope">
            <span style="color:green;" v-if="scope.row.returnMark == 1">未退款</span>
            <span style="color:red;" v-if="scope.row.returnMark == -1">有退款</span>
          </template>
          <template v-slot:profitPrice="scope">
            <el-text v-if="scope.row.paybackPrice != null && scope.row.costPrice != null" type="primary"
              title="估算=回款-成本(未包含税/包装/快递等费用)">
              {{ (scope.row.paybackPrice - scope.row.costPrice).toFixed(2) }}
            </el-text>
            <el-text v-else-if="scope.row.paybackPrice == null" type="danger" size="small">
              缺失回款额
            </el-text>
            <el-text v-else-if="scope.row.costPrice == null" type="danger" size="small">
              缺失成本额
            </el-text>
          </template>
        </static-table>
      </el-tab-pane>

      <el-tab-pane label="售后订单" name="return" class="horizbox">
        <el-form label-width="90px" :model="returnParams" ref="returnParams" class="search-form">
          <el-row>
            <el-form-item label="关键词" prop="keyword" label-width="80px">
              <el-col>
                <el-input v-model="returnParams.keyword" @keyup.enter="fetchs" prefix-icon="Search"
                  style="width:150px"></el-input>
              </el-col>
            </el-form-item>

            <el-form-item label="渠道平台" prop="platform" label-width="90px">
              <el-col style="width: 150px">
                <!-- <el-select v-model="platform" placeholder="渠道平台" clearable @change="fetchs()" style="max-width: 200px">
                  <el-option v-for="dd in dict.platform" :key="dd.value" :label="dd.label" :value="dd.value">
                  </el-option>
                </el-select> -->
                <el-select v-model="platform" placeholder="渠道平台" clearable @change="fetchs()" style="max-width: 200px">
                  <el-option label="标准模板" value="normal"></el-option>
                  <el-option label="招行" value="zhaohang"></el-option>
                  <el-option label="广发" value="gaungfa"></el-option>
                </el-select>
              </el-col>
            </el-form-item>

            <el-form-item label="" label-width="20px">
              <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
            </el-form-item>
          </el-row>
        </el-form>

        <div class="toolbar" style="margin-top: 0;">
          <el-button plain type="info" icon="download" @click="fetchs('Export')" style="float:right;">导出明细</el-button>
          <el-button plain type="primary" icon="upload" style="float:right; margin-right: 15px;"
            @click="openUploadDialog()">导入售后单</el-button>
          <div style="clear:both;"></div>
        </div>

        <static-table class="table" :pager="returnPager" :columns="returnParams.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">
          <template v-slot:returnType="scope">
            <span style="color:gray;" v-if="scope.row.returnType == 0">直接退款</span>
            <span style="color:blue;" v-else-if="scope.row.returnType == 10">退货退款</span>
            <span style="color:red;" v-else-if="scope.row.returnType == 20">仅退款</span>
            <span style="color:cadetblue;" v-else-if="scope.row.returnType == 30">售后换新</span>
            <span style="color:black;" v-else>其他</span>
          </template>
          <template v-slot:status="scope">
            <span style="color:gray;" v-if="scope.row.status == 0">默认</span>
            <span style="color:brown;" v-else-if="scope.row.status == 1">待处理</span>
            <span style="color:blue;" v-else-if="scope.row.status == 50">处理中</span>
            <span style="color:green;" v-else-if="scope.row.status == 200">退款成功</span>
            <span style="color: rebeccapurple;" v-else-if="scope.row.status == -2">退款失败</span>
            <span style="color:red;" v-else-if="scope.row.status == -10">退款关闭</span>
            <span style="color:gray;" v-else>其他</span>
          </template>
        </static-table>
      </el-tab-pane>
    </el-tabs>


  </el-card>

  <static-dialog ref="uploadDialog" class="dialog" title="上传渠道平台订单/售后单">
    <div class="load-dialog" style="text-align:center; max-width:500px;margin:0px auto;">
      <h4>上传前请检查文件格式是否为**.xlsx后缀(可另存之后再上传)</h4>
      <br>
      <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable
        @change="departmentId = ''; companyChange()" check-strictly :render-after-expand="false" placeholder="选择订单归属公司"
        style="max-width:220px; margin-right: 15px;" />

      <el-tree-select ref="departments" v-model="departmentId" :data="departments" check-strictly
        :render-after-expand="false" placeholder="归属部门" style="max-width:120px" />

      <el-select v-model="platform" placeholder="请选择要各渠道平台对应的订单模板" style="margin:15px auto; min-width: 50%;">
        <el-option v-for="dd in dict.platform" :key="dd.value" :label="dd.label" :value="dd.value">
        </el-option>
      </el-select>

      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" drag :on-change="handleChange"
        :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
        :auto-upload="true" :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>

      <!-- <el-link type="primary" @click="download()">点击下载该平台的订单导入模板.xlsx</el-link> -->
    </div>
  </static-dialog>


  <static-dialog ref="uploadFeeDialog" class="dialog" title="上传渠道订单扣点信息">
    <div class="load-dialog" style="text-align:center; max-width:550px;margin:0px auto;">
      <h4>上传前请检查文件格式是否为表格格式，导入的表格中可修改的信息有（售卖金额、支付金额、积分补贴、优惠券补贴、其他补贴、平台扣点）其他信息将会忽略</h4>
      <br>
      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" drag :on-change="handleChange"
        :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
        :auto-upload="true" :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>
    </div>
  </static-dialog>
</template>
<script>
import request from '@/utils/request'
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { fetchOrderSellInfo, fetchOrderReturn, importOrderSellInfo, importOrderModify, importOrderReturn } from "@/api/order";
import { uploader } from "@/api/subupload";
export default {
  name: "orderSellInfo",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      tabName: 'sellinfo',
      orderParams: {
        keyword: "",
        filters: [],
        columns: [
          // { type: "selection", width: "55" },
          { type: "index", label: "序号" },
          { prop: "orderNo", label: "订单号", width: "180", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "paybackPrice", label: "应回款金额", width: "80", },
          { prop: "costPrice", label: "综合成本", width: "75", },
          { type: "template", prop: "profitPrice", label: "估算利润", width: "90", },

          { prop: "companyName", label: "公司名称", width: "160", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "departmentName", label: "部门", width: "80", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "platformName", label: "平台", width: "70", searchable: true, showOverflowTooltip: true, },
          { prop: "platformOrderNo", label: "平台订单号", width: "155", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "platformOrderSubNo", label: "子订单号", width: "130", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "platformOrderDate", label: "订单时间", width: "140", sortable: true, searchable: true, showOverflowTooltip: true, },
          //{ prop: "platformRemark", label: "订单备注", width: "120", sortable: true, searchable: true, showOverflowTooltip: true, },

          { type: "template", prop: "paymentStatus", label: "付款状态", width: "65", },
          { type: "template", prop: "returnMark", label: "退款状态", width: "65", },

          { prop: "platformProductName", label: "商品名称", width: "150", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "platformProductCode", label: "上架编码", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "platformProductQty", label: "数量", width: "50" },
          { prop: "platformProductSpec", label: "商品描述", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },
          //{ prop: "platformSerialCode", label: "上架条码", width: "100", sortable: true, searchable: true, showOverflowTooltip: true, },

          { prop: "sellingPrice", label: "售卖金额", width: "90", sortable: true, },
          { prop: "platformPayDate", label: "支付时间", width: "140", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "paymentPrice", label: "支付金额", width: "90", sortable: true, },

          { prop: "pointsPrice", label: "积分补贴", width: "70", },
          { prop: "couponPrice", label: "优惠券补贴", width: "80" },
          { prop: "othersPrice", label: "其他补贴", width: "65", },

          { prop: "chargePrice", label: "平台扣点", width: "70", },

          { prop: "actbackPrice", label: "实际回款额", width: "80", },
          { type: "template", prop: "paybackStatus", label: "回款状态", width: "65", },

          //{ prop: "orderStatus", label: "订单状态", width: "90", sortable: true, },

          { prop: "receiverName", label: "收件人", width: "60", searchable: true, showOverflowTooltip: true },
          //{ prop: "receiverMobile", label: "手机号", width: "100", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "receiverProvince", label: "省份", width: "75", searchable: true, showOverflowTooltip: true },
          //{ prop: "receiverCity", label: "城市", width: "85", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "postName", label: "快递名称", width: "80", searchable: true, showOverflowTooltip: true },
          { prop: "postNo", label: "快递单号", width: "100", searchable: true, showOverflowTooltip: true },
          { prop: "postFee", label: "快递费", width: "70", showOverflowTooltip: true },

          { prop: "channel", label: "发货渠道", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "storeCode", label: "发货仓库", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "detailCode", label: "发货款号", width: "100", searchable: true, showOverflowTooltip: true },
          { prop: "serialCode", label: "发货批次号", width: "100", searchable: true, showOverflowTooltip: true },


          { prop: "brandName", label: "品牌", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "brandModelCode", label: "品牌款号", width: "100", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "productQty", label: "发货数", width: "70", },
          { prop: "purchasePrice", label: "采购价", width: "75", },
          { prop: "productPrice", label: "货品成本", width: "75", },
          { prop: "bagPrice", label: "辅料价", width: "65" },

          { prop: "taxPrice", label: "综合税", width: "70" },


          { prop: "orderFrom", label: "数据来源", width: "80", showOverflowTooltip: true, },

          { prop: "createDate", label: "导入时间", width: "140", sortable: true, },
          // { type: "toolbar", label: "操作", width: "80" },
        ],
      },
      orderPager: {
        sidx: "platformOrderDate",
        sord: "descending",
      },
      returnParams: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          { prop: "companyName", label: "公司名称", width: "160", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "departmentName", label: "部门名称", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "platformName", label: "平台", width: "70", searchable: true, showOverflowTooltip: true, },
          { prop: "platformReturnNo", label: "退款单号", width: "125", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "platformOrderNo", label: "原订单号", width: "145", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "platformOrderSubNo", label: "原子单号", width: "130", sortable: true, searchable: true, showOverflowTooltip: true, },

          { type: "template", prop: "returnType", label: "售后类型", width: "65", },
          { type: "template", prop: "status", label: "售后状态", width: "65", },

          { prop: "platformProductName", label: "商品名称", width: "150", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "returnFee", label: "退款金额", width: "70" },
          { prop: "returnQty", label: "退款数量", width: "70" },

          { prop: "applicationDate", label: "申请时间", width: "140", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "returnDate", label: "退款时间", width: "140", sortable: true, searchable: true, showOverflowTooltip: true, },

          { prop: "returnPostName", label: "快递名称", width: "80", searchable: true, showOverflowTooltip: true },
          { prop: "returnPostNo", label: "快递单号", width: "100", searchable: true, showOverflowTooltip: true },

          { prop: "returnReason", label: "售后原因", width: "110", showOverflowTooltip: true, },
          { prop: "returnDesc", label: "用户描述", width: "110", showOverflowTooltip: true, },

          { prop: "channel", label: "渠道", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "storeCode", label: "仓库", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "detailCode", label: "款号", width: "100", searchable: true, showOverflowTooltip: true },
          { prop: "serialCode", label: "批次号", width: "100", searchable: true, showOverflowTooltip: true },

          { prop: "brandName", label: "品牌", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "brandModelCode", label: "品牌款号", width: "100", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "orderFrom", label: "数据来源", width: "100", showOverflowTooltip: true, },

          { prop: "createDate", label: "导入时间", width: "140", sortable: true, },
          // { type: "toolbar", label: "操作", width: "80" },
        ],
      },
      returnPager: {
        sidx: "applicationDate",
        sord: "descending",
      },
      platform: '',
      delivery: null,
      paybackPrice: null,
      costPrice: null,
      currRow: null,
      selects: [],
      loading: false,
      fileList: [],

      dateRange: [],

      dict: {
        platform: [],
      },

      rules: {
      },

      companyId: '',
      departmentId: '',
      departments: [],
      companys: [],

      uploadMark: '',
    };
  },
  methods: {
    tabChange() {
      this.fetchs()
    },
    fetchs(methodName) {
      if (this.tabName == "sellinfo") {
        this.loadSellInfo(methodName);
      } else if (this.tabName == "return") {
        this.loadReturn(methodName);
      }
    },

    loadSellInfo(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      let that = this;
      this.orderParams.filters = [];
      let { data, records, total, ...search } = {
        ...this.orderPager,
        ...this.orderParams,
      };
      if (this.dateRange && this.dateRange[0])
        search.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        search.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });
      if (this.platform)
        search.filters.push({
          name: "platformName",
          value: "%" + this.platform + "%",
          operate: 5,
        });

      if (this.delivery == '1') {
        search.filters.push({
          name: "productQty",
          value: '0',
          operate: 1,
        });
      }
      else if (this.delivery == '-1') {
        search.filters.push({
          name: "productQty",
          value: null,
          operate: 0,
        });
      }

      if (this.paybackPrice == '1') {
        search.filters.push({
          name: "paybackPrice",
          value: '0',
          operate: 1,
        });
      }
      else if (this.paybackPrice == '0') {
        search.filters.push({
          name: "paybackPrice",
          value: '0',
          operate: 0,
        });
      }
      else if (this.paybackPrice == '-1') {
        search.filters.push({
          name: "paybackPrice",
          value: null,
          operate: 0,
        });
      }

      if (this.costPrice == '1') {
        search.filters.push({
          name: "costPrice",
          value: '0',
          operate: 1,
        });
      }
      else if (this.costPrice == '0') {
        search.filters.push({
          name: "costPrice",
          value: '0',
          operate: 0,
        });
      }
      else if (this.costPrice == '-1') {
        search.filters.push({
          name: "costPrice",
          value: null,
          operate: 0,
        });
      }

      if (this.companyId && this.companyId.length > 0) {
        search.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }
      if (this.departmentId != null && this.departmentId.length > 0) {
        search.filters.push({
          name: "departmentId",
          value: this.departmentId,
          operate: 6,
        });
      }

      search.method = methodName || '';

      fetchOrderSellInfo(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.orderPager = { ...res.data };
        }
      })
        .catch(() => {
          that.$message({ type: "info", message: "查询失败", });
        });
    },
    loadReturn(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      let that = this;
      this.returnParams.filters = [];
      let { data, records, total, ...search } = {
        ...this.returnPager,
        ...this.returnParams,
      };

      if (this.platform)
        this.returnParams.filters.push({
          name: "platformName",
          value: this.platform,
          operate: 0,
        });

      search.method = methodName || '';

      fetchOrderReturn(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.returnPager = { ...res.data };
        }
      })
        .catch(() => {
          that.$message({ type: "info", message: "查询失败", });
        });
    },

    syncDelivery() {
      let that = this;
      request({
        url: '/OrderSellInfo/SyncDeliveryGoods',
        method: 'get',
        params: { platform: this.platform }
      }).then((res) => {
        that.$message({ type: res.type, message: res.content, });
      });
    },
    syncPreOrders() {
      let startDateStr = '';
      if (this.dateRange && this.dateRange[0])
        startDateStr = this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd");

      let that = this;
      request({
        url: '/OrderSellInfo/SyncPreOrders',
        method: 'get',
        params: { platform: this.platform, startDate: startDateStr }
      }).then((res) => {
        that.$message({ type: res.type, message: res.content, });
      });
    },
    openUploadDialog(row) {
      let that = this;
      that.$refs.uploadDialog.toggle();
    },
    openUploadFeeDialog(row) {
      let that = this;
      this.uploadMark = 'modify';
      that.$refs.uploadFeeDialog.toggle();
    },

    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },

    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "Import" + this.tabName);
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else
            this.$message.error("上传失败，请重试");
        })
        .then(() => {
          this.loading = false;

          if (this.tabName == "sellinfo") {
            if (this.uploadMark == 'modify') {
              importOrderModify(this.dataId).then(
                (res) => { this.fetchs(); this.$alert(res.content, '提示'); },
                (err) => { this.$alert(err.content, '提示'); }
              );
            } else {
              importOrderSellInfo(this.departmentId, this.platform, this.dataId).then(
                (res) => { this.fetchs(); this.$alert(res.content, '提示'); },
                (err) => { this.$alert(err.content, '提示'); }
              );
            }
          } else if (this.tabName == "return") {
            importOrderReturn(this.departmentId, this.platform, this.dataId).then(
              (res) => { this.fetchs(); this.$alert(res.content, '提示'); },
              (err) => { this.$alert(err.content, '提示'); }
            );
          }
          this.uploadMark = '';
        });

      this.fileList = [];
    },

    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      if (this.tabName == "sellinfo") {
        this.orderPager.page = page;
      } else if (this.tabName == "return") {
        this.returnPager.page = page;
      }
      this.fetchs();
    },
    sizeHandler(rows) {
      if (this.tabName == "sellinfo") {
        this.orderPager.rows = rows;
      } else if (this.tabName == "return") {
        this.returnPager.rows = rows;
      }
      this.fetchs();
    },
    sortHandler(order) {
      if (this.tabName == "sellinfo") {
        this.orderPager.sidx = order.prop;
        this.orderPager.sord = order.order;
      } else if (this.tabName == "return") {
        this.returnPager.sidx = order.prop;
        this.returnPager.sord = order.order;
      }

      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },

    companyChange() {
      if (!this.companyId) return;

      var that = this;
      this.$kaung.departments(this.companyId).then((res) => {
        that.departments = res;
        that.fetchs();
      });
    },
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue.length > 0 && newValue != oldValue) {
        this.$util.setLocalstorage('companyIdStr', newValue);
      }
      else {
        this.$util.setLocalstorage('companyIdStr', '');
      }
    },
    // orderPager: function (newValue, oldValue) {
    //   this.$util.setPageCache('keyword', newValue.keyword);
    //   if (newValue.page)
    //     this.$util.setPageCache('page', newValue.page);
    //   if (newValue.rows)
    //     this.$util.setPageCache('rows', newValue.rows);
    //   if (newValue.sidx)
    //     this.$util.setPageCache('sidx', newValue.sidx);
    //   if (newValue.sord)
    //     this.$util.setPageCache('sord', newValue.sord);
    // }
  },
  created: function () {
    // this.orderParams.keyword = this.$util.getPageCache('keyword');

    // this.orderPager.page = parseInt(this.$util.getPageCache('page') || 1);
    // this.orderPager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    // let sidx = this.$util.getPageCache('sidx');
    // if (sidx) this.orderPager.sidx = sidx;
    // let sord = this.$util.getPageCache('sord');
    // if (sord) this.orderPager.sord = sord;

    let companyIdStr = this.$route.query.companyId || this.$util.getLocalstorage('companyIdStr');
    if (companyIdStr != '' && companyIdStr.length > 0) {
      this.companyId = companyIdStr;

      this.companyChange();
    }
    this.departmentId = this.$route.query.departmentId || '';

    this.dateRange = JSON.parse(this.$route.query.dateRange || '[]');
    if (this.dateRange.length == 0) {
      this.dateRange = this.$util.getCurrentDate();
    }

    this.$kaung.companys().then((res) => {
      this.companys = res;
    });

    this.$kaung.dictionary("workflow", "platform").then(res => this.dict.platform = res);
    this.fetchs();
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init(this.$options.name);
  },
};
</script>
<style lang="scss" scoped></style>